import React, { Component } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ContentBox from 'components/Shared/ContentBox/ContentBox';
import { parseHash, replaceHash } from 'components/Shared/Utils';
import SolutionCard from './SolutionCard';
import SolutionsMenu from './SolutionsMenu';
import IconArrowLeft from 'static/images/icons/chevron-left.inline.svg';
import IconArrowLeftLight from 'static/images/icons/chevron-left-light.inline.svg';
import IconArrowRight from 'static/images/icons/chevron-right.inline.svg';
import IconArrowRightLight from 'static/images/icons/chevron-right-light.inline.svg';
import './SolutionsContainer.scss';


class SolutionsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: 0,
    };
  }

  componentDidMount() {
    let hash = parseHash();
    if (hash) {
      this.onHashChange();
    }
    window.addEventListener('hashchange', this.onHashChange);
  }

  componentWillUnmount() {
    window.removeEventListener('hashchange', this.onHashChange);
  }

  onHashChange = () => {
    let hash = parseHash();
    if (hash) {
      const selectedItem = this.props.data.items.findIndex(
        item => item.id == hash
      );
      if (selectedItem !== -1) {
        this.onChangeItem(selectedItem);
      }
    } else {
      this.onChangeItem(0);
    }
  };

  onChangeItem = index => {
    this.setState({ selectedItem: index }, () => {
      replaceHash(this.props.data.items[index].id);
    });
  };

  render() {
    return (
      <article className="aaa-solutions">
        <ContentBox>
          <h2 className="aaa-solutions__title">{this.props.data.title}</h2>
        </ContentBox>
        <div className="aaa-solutions__container">
          <SolutionsMenu
            items={this.props.data.items}
            selected={this.state.selectedItem}
            onChange={this.onChangeItem}
          />
          <Carousel
            className="aaa-solutions__carousel"
            showThumbs={false}
            showStatus={false}
            showIndicators
            infiniteLoop={false}
            swipeScrollTolerance={100}
            verticalSwipe="natural"
            selectedItem={this.state.selectedItem}
            showArrows={true}
            onChange={this.onChangeItem}
            renderArrowNext={(clickHandler, hasNext) => (
              <button
                disabled={!hasNext}
                className="control-arrow control-next"
                aria-label="next slide/item"
                onClick={clickHandler}
              >
                <IconArrowRight className="regular" />
                <IconArrowRightLight className="light" />
              </button>
            )}
            renderArrowPrev={(clickHandler, hasPrev) => (
              <button
                disabled={!hasPrev}
                className="control-arrow control-prev"
                aria-label="previous slide/item"
                onClick={clickHandler}
              >
                <IconArrowLeft className="regular" />
                <IconArrowLeftLight className="light" />
              </button>
            )}
          >
            {this.props.data.items.map((item, i) => (
              <SolutionCard key={`solution_${i}`} data={item} />
            ))}
          </Carousel>
        </div>
      </article>
    );
  }
}

export default SolutionsContainer;
