import React, { Component } from 'react';
import Swipe from 'react-easy-swipe';
import './SolutionsMenu.scss';


class SolutionsMenu extends Component {

  constructor(props) {
    super(props);
    this.items = props.items.map((item, index) => { 
      return {
        key: `solutions_menu_${index}`,
        ref: null,
        title: item.title,
        id: item.id
      }
    });
    this.wrapper = null;
    this.swipeDelta = 0;
    this.state = {
      marker: {
        left: 0,
        width: 0,
      },
      swipe: false,
      translate: 0,
    }
    this.timer = null;
  }

  componentDidMount() {
    this.moveToVisibleArea();
    this.moveMarker();
    // TODO: Remove timeout and set initial marker size
    //       and position when all styles applied
    this.timer = setTimeout(() => {
      this.moveToVisibleArea();
      this.moveMarker();
    }, 1000);
    window && window.addEventListener("resize", this.onResize);
  }

  componentWillUnmount() {
    window && window.removeEventListener("resize", this.onResize);
    clearTimeout(this.timer);
  }

  onResize = () => {
    this.setState({ translate: 0 });
    this.moveToVisibleArea();
    this.moveMarker();
  }

  componentDidUpdate(prevProps) {
    if(this.props.selected !== prevProps.selected) {
      this.moveToVisibleArea();
      this.moveMarker();
    }
  }
  
  moveToVisibleArea() {
    const padding = parseFloat(getComputedStyle(this.wrapper).paddingRight);
    const left = this.items[this.props.selected].ref.offsetLeft - padding;
    const right = left + this.items[this.props.selected].ref.clientWidth + padding*2;
    if(this.state.translate + left < 0) {
      this.setState({ translate: -left });
    } else if(this.wrapper.clientWidth - this.state.translate < right) {
      this.setState({ translate: (right - this.wrapper.clientWidth) * -1 });
    }
  }

  moveMarker() {
    this.setState({
      marker: {
        left: this.items[this.props.selected].ref.offsetLeft,
        width: this.items[this.props.selected].ref.clientWidth
      }
    });
  }

  onSwipeEnd = () => {
    let translate = this.state.translate+this.swipeDelta;
    const maxTranslate = this.wrapper.scrollWidth - this.wrapper.clientWidth 
      + parseFloat(getComputedStyle(this.wrapper).paddingRight);
    if(translate > 0) {
      translate = 0;
    } else if(translate*(-1) > maxTranslate) {
      translate = maxTranslate * (-1);
    }
    this.wrapper.style.transform = `translateX(${translate}px)`; 
    this.setState({
      swipe: false,
      translate: translate
    });
    this.swipeDelta = 0;
  }

  onSwipeMove = (position) => {
    this.swipeDelta = position.x;
    this.wrapper.style.transform = `translateX(${this.state.translate+this.swipeDelta}px)`;    
  }

  onSwipeStart = () => {
    this.setState({ swipe: true });
  }

  render() {
    return (
      <Swipe
        onSwipeEnd={this.onSwipeEnd}
        onSwipeMove={this.onSwipeMove}
        onSwipeStart={this.onSwipeStart}
      >
        <div className="aaa-solutions__menu">
          <div 
            className={`aaa-solutions__menu__wrapper ${this.state.swipe ? '' : 'animated'}`}
            ref={ el => this.wrapper = el }
            style={{ transform: `translateX(${this.state.translate}px)` }}
          >
            { this.items.map((item, index) => (
              <div 
                className={`aaa-solutions__menu__item ${this.props.selected === index ? 'active' : ''}`}
                key={item.key}
                ref={ el => this.items[index].ref = el }
                id={item.id}
                scrolloffset={-220}
                onClick={() => this.props.onChange(index)}
              >
                {item.title}
              </div>
            ))}
            <div 
              className="aaa-solutions__menu__marker" 
              style={{left: `${this.state.marker.left}px`, width: `${this.state.marker.width}px`}} 
            />
          </div>
        </div>
      </Swipe>
    );
  }
}

export default SolutionsMenu;