import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout/Layout';
import SEO from 'components/SEO/SEO';
import ZipGateContainer from 'components/ZipGateContainer/ZipGateContainer';
import Hero from 'components/Hero/Hero';
import CTAForm from 'components/CTAForm';
import InfoCardBig from 'components/InfoCardBig/InfoCardBig';
import { CallUsBlock, KitsTiles } from 'components/ProductKits';
import SolutionsContainer from 'components/SolutionsContainer';
import ScrollToTopButton from 'components/ScrollToTopButton';
import ZipContext from 'components/Shared/ZipContext';


const SolutionsPage = ({ data, location }) => (
  <Layout location={location} type="standard">
    <SEO
      meta={data.solutionsYaml.metaTags}
      title={data.solutionsYaml.title}
    />
    <Hero data={data.solutionsYaml.hero} />
    <ZipContext.Consumer>
      {zipContext => (
        <Fragment>
          <KitsTiles
            customizable={true}
            data={data.productBundles}
            type={zipContext.fullAccess ? 'pro' : 'self'} 
          />
          {zipContext.fullAccess && <InfoCardBig data={data.solutionsYaml.bigInfoCard} />}
        </Fragment>
      )}
    </ZipContext.Consumer>
    <CallUsBlock data={data.solutionsYaml.callUs} />
    <SolutionsContainer
      data={data.solutionsYaml.solutions}
      location={location}
    />
    <hr />
    <CTAForm />
    <ZipGateContainer location={location} />
    <ScrollToTopButton />
  </Layout>
);

export default SolutionsPage;

export const pageQuery = graphql`
  query SolutionsPageQuery {
    solutionsYaml {
      id
      title
      metaTags {
        name
        content
      }
      hero {
        size
        title
        subtitle
        heroImage {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        heroImagePosition
        primaryButton {
          text
          url
        }
        secondaryButton {
          text
          url
        }
      }
      bigInfoCard {
        color
        type
        title
        description
        image {
          childImageSharp {
            fluid(maxWidth: 891, maxHeight: 521) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      callUs
      solutions {
        title
        items {
          id
          title
          image {
            childImageSharp {
              fluid(maxWidth: 485) {
                ...GatsbyImageSharpFluid_withWebp
              }
              original {
                height
                width
              }
            }
          }
          tiles {
            title
            description
          }
          products {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 85) {
                  ...GatsbyImageSharpFluid_withWebp
                }
                original {
                  height
                  width
                }
              }
            }
            url
          }
          nonServiceExclude
        }
      }
    }

    productBundles: productBundlesYaml {
      bundles {
        productId
        kit
        type
        title
        subtitle
        link {
          url
          text
        }
        description
        icon {
          childImageSharp {
            fluid(maxWidth: 100, maxHeight: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image {
          childImageSharp {
            fluid(maxWidth: 1050, maxHeight: 780) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        images {
          childImageSharp {
            fluid(maxWidth: 1700, maxHeight: 1400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        devices {
          title
          items
        }
        addons {
          title
          text
        }
        price
        requirements {
          text
          button
          planId
        }
        button
      }
      blockData {
        type
        title
        description
        disclaimer
      }
    }
  }
`;
