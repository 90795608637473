import React from 'react';
import Img from 'gatsby-image';
import ContentBox from 'components/Shared/ContentBox/ContentBox';
import Link from 'components/Shared/Link/Link';
import ZipContext from 'components/Shared/ZipContext';
import './SolutionCard.scss';


const SolutionCard = ({ data }) => {
  let largestTitle = '';
  data.tiles.forEach(tile => {
    if(largestTitle.length < tile.title.length) {
      largestTitle = tile.title;
    }
  });  
  return (
    <ZipContext.Consumer>
      {zipContext => {
        let products;
        if(!data.nonServiceExclude || zipContext.fullAccess || !data.products) {
          products = data.products
        } else {
          products = data.products.filter(p => !data.nonServiceExclude.includes(p.title));
        }
        return (
          <ContentBox>
            <section className="aaa-solution-card">
              <div className="aaa-solution-card__info-box">
                <h3 className="aaa-solution-card__title">
                  {data.title}
                </h3>
                <div className="aaa-solution-card__tiles">
                  { data.tiles.map( (tile, index) => (
                    <div
                      className="aaa-solution-card__tile"
                      key={`${data.title.replace(' ', '')}_${index}`}
                    >
                      <div className="aaa-solution-card__tile__title">
                        <div className="aaa-solution-card__tile__title-padding">
                          {largestTitle}
                        </div>
                        <div className="aaa-solution-card__tile__title-text">
                          {tile.title}
                        </div>
                      </div>
                      <div 
                        className="aaa-solution-card__tile__description"
                        dangerouslySetInnerHTML={{__html: tile.description}}
                      />
                    </div>
                  ))}
                </div>
                {
                  products && 
                  <React.Fragment>
                    <div className="aaa-solution-card__products-title">
                      {`${data.title} Products`}
                    </div>
                    <div className="aaa-solution-card__products">
                      { products.map( (product, index) => (
                        <Link
                          className="aaa-solution-card__product"
                          key={`${data.title.replace(' ', '')}_product_${index}`}
                          to={product.url}
                        >
                          <Img
                            className="aaa-solution-card__product__image"
                            fluid={product.image.childImageSharp.fluid}
                          />
                          <div className="aaa-solution-card__product__title">
                            {product.title}
                          </div>
                        </Link>
                      ))}
                    </div>
                  </React.Fragment>
                }
              </div>
              <div className="aaa-solution-card__image-box">
                <Img
                  className="aaa-solution-card__image"
                  fluid={data.image.childImageSharp.fluid}
                  imgStyle={{ objectFit: "contain" }}
                />
              </div>
            </section>
          </ContentBox>
        );
      }}        
    </ZipContext.Consumer>
  );
};

export default SolutionCard;
